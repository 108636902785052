@import "../../../global/colors";
@import "../../../global/variables";

.accordion-faq {
    border-bottom: 1px solid $silver-grey;

    .header-faq {
        cursor: pointer;
        font-size: 1rem;
        font-weight: $fontweight-light;

        .icon {
            right: 0;
            top: rem(15);
        }

        .up {
            display: none;
        }

        &:not(.collapsed) {
            .down {
                display: none;
            }

            .up {
                display: inline-block;
            }
        }
    }
}
