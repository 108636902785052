@import "../global/variables";
@import "bootstrap/scss/mixins/breakpoints";

.footer-signup-modal {
    .modal-body {
        .nb-rewards {
            font-size: 1rem;
            font-weight: $fontweight-light;
            line-height: 1.5rem;
        }

        .privacy-policy {
            font-size: 1rem;
            font-weight: $fontweight-light;
            line-height: 1.5rem;
            margin-top: 1.5rem;

            a {
                text-decoration: underline;
                color: $black;
                font-weight: $fontweight-semibold;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    #subscribe-global-modal {
        .modal-header {
            padding-top: rem(32);
        }
    }
}
