@import "../../../global/colors";
@import "../../../global/variables";
@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.text-component-container {
    h1,
    h2,
    h3,
    h4,
    p,
    li,
    a {
        color: $black;
        letter-spacing: 0;
    }

    // White text when background is black
    &.text-component-white {
        h1,
        h2,
        h3,
        h4,
        p,
        li,
        a {
            color: $white !important;
        }
    }

    // 'Subheader 1' styling
    h1 {
        font-family: $nb-page-heading-font;
        font-size: rem(56);
        line-height: rem(60);
    }

    // 'Header 1' styling
    h2 {
        font-family: $body-font-family;
        font-size: rem(32);
        font-weight: $fontweight-bold;
        line-height: rem(40);
    }

    // 'Header 2' styling
    h3 {
        font-family: $body-font-family;
        font-size: rem(24);
        font-weight: $fontweight-bold;
        line-height: rem(32);
    }

    // using h4 as disclaimer
    h4 {
        font-family: $body-font-family;
        font-size: rem(12);
        font-weight: $fontweight-regular;
        line-height: rem(16);
    }

    p,
    li {
        font-family: $body-font-family;
        font-size: rem(16);
        font-weight: $fontweight-regular;
        line-height: rem(24);
    }

    a {
        text-decoration: underline;
    }

    ul {
        list-style: disc inside;
    }

    ol {
        list-style: decimal inside;
    }

    // Add red lines below Header 1 and 2 when user checks box
    &.text-component-red-line {
        h1::after,
        h2::after {
            background-color: $nb-red;
            content: '';
            display: block;
            height: rem(4);
            position: relative;
            width: rem(80);
        }

        h1 {
            margin-bottom: rem(24);

            &::after {
                margin-top: 1.5rem;
            }
        }

        h2 {
            margin-bottom: rem(16);

            &::after {
                margin-top: rem(12);
            }
        }

        &.text-center {
            h1::after,
            h2::after {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &.icon-text-center {
        padding-left: rem(8) !important;
        padding-right: rem(8) !important;
    }

    .disclaimer-pgp {
        h1,
        h2,
        h3,
        h4,
        p,
        li,
        a {
            color: #5c6268 !important;
            font-size: rem(12);
            line-height: rem(18);
        }
    }

    // Container widths for icon text component
    @include media-breakpoint-up(lg) {
        .icon-text-w-50 {
            width: 50%;
        }

        .icon-text-w-75 {
            width: 75%;
        }
    }
}
