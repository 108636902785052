@import "bootstrap/scss/mixins/breakpoints";
@import "../../../variables";

$xl: 1440px;

.visual-navigation {
    @media (min-width: $xl) {
        .mx-lg-11 {
            margin-right: rem(80) !important;
            margin-left: rem(80) !important;
        }
    }

    .slick-dots {
        bottom: 0 !important;
    }
}
