@import '../../../global/variables';
@import 'bootstrap/scss/mixins/breakpoints';

$breakpoint-small: 544px;

.hcb-container-main {
    margin-bottom: 2px;

    &.overlay {
        position: relative;
        overflow: hidden;
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: right;
    }

    .nb-pd-video {
        .video-controls {
            &.overlay {
                &.right {
                    right: 0;
                    left: 24px;
                }
            }
        }

        .hcb-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
            pointer-events: none;
            z-index: 2;

            &.bg-white {
                background-color: #fff;
            }
        }
    }

    .hcb-container {
        max-width: 700px;

        &.mobile-base {
            padding-top: 1.25rem !important;
            width: 100%;
        }

        &.overlay {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
            z-index: 3;

            &.vcenter {
                top: 50%;
            }

            &.vbottom {
                top: 60%;

                @include media-breakpoint-up(lg) {
                    top: 60%;
                }

                @include media-breakpoint-up(xl) {
                    top: 66%;
                }

                @include media-breakpoint-down(sm) {
                    top: 66%;
                }

                &.pgp {
                    top: 60% !important;

                    &.v3 {
                        top: 65% !important;
                    }

                    &.v2 {
                        top: 75% !important;
                    }

                    &.v1 {
                        top: 80% !important;
                    }
                }

                &.v3 {
                    top: 67%;

                    @include media-breakpoint-down(md) {
                        top: 67%;
                    }
                }

                &.v2 {
                    top: 70%;

                    @include media-breakpoint-down(md) {
                        top: 67%;
                    }
                }

                &.v1 {
                    top: 85%;

                    @include media-breakpoint-down(md) {
                        top: 90%;
                    }
                }
            }

            &.left {
                text-align: left;
                left: 355px;

                @media screen and (max-width: $breakpoint-small) {
                    left: 50%;
                }
            }

            &.center {
                left: 50%;
                justify-content: center;
            }

            &.right {
                text-align: right;
                right: -275px;

                @media screen and (max-width: $breakpoint-small) {
                    right: -50%;
                }
            }
        }

        &.center {
            justify-content: center;
        }

        &.left {
            text-align: left;
        }

        &.right {
            text-align: right;
            justify-content: right;
        }

        .headline-container {
            font-family: $nb-page-heading-font !important;
            font-size: 3.25rem;
            line-height: 3.5rem;
            width: 100%;
            margin: auto;

            &.center {
                text-align: center;
                justify-content: center;
            }

            &.right {
                text-align: right;
                justify-content: right;
                width: 100%;
            }

            &.left {
                text-align: left;
                justify-content: left;
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: 2.25rem;
            }

            @media screen and (max-width: $breakpoint-small) {
                margin-bottom: 0 !important;
            }
        }

        .intro-container,
        .body-container {
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-family: $body-font-family !important;
            width: 100%;
            margin: auto;

            &.center {
                text-align: center;
                justify-content: center;
            }

            &.right {
                text-align: right;
                justify-content: right;
            }
        }

        .body-container {
            p {
                margin-bottom: 0;
            }

            &.light-color-mode {
                h1,
                h2,
                h3,
                h4,
                p {
                    color: $white !important;
                }
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 0.5rem !important;
            }
        }

        .cta-container {
            &.cta-update {
                width: 100%;
            }

            &.center {
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
            }

            &.left {
                justify-content: left;
            }

            &.right {
                justify-content: right;
            }

            .cta-box {
                .nb-button {
                    font-size: 0.875rem;
                    line-height: 1rem;
                    border-radius: 4px;
                    padding: 0.625rem 1rem;
                }
            }
        }
    }

    .disclaimer-container {
        font-size: 0.75rem;
        line-height: 1.125rem;

        &.mobile-base {
            color: #5c6268;

            span {
                color: #5c6268 !important;
                font-size: 0.75rem;
                line-height: 1.125rem;
            }
        }

        &.overlay {
            position: absolute;
            bottom: 1rem;
            z-index: 3;

            @include media-breakpoint-down(md) {
                bottom: 0.5rem;
            }

            &.center {
                text-align: center;

                @include media-breakpoint-down(md) {
                    padding-left: 5px !important;
                    padding-right: 5px !important;
                }
            }

            @include media-breakpoint-down(md) {
                bottom: 0.1rem;
            }
        }

        &.left {
            text-align: left;
        }

        &.center {
            text-align: center;

            .disclaimer-box {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &.right {
            text-align: right;

            .disclaimer-box {
                margin-left: auto;
            }
        }

        .disclaimer-box {
            max-width: 1000px;

            &.light-color-mode {
                h1,
                h2,
                h3,
                h4,
                p {
                    color: $white !important;
                }
            }
        }

        @include media-breakpoint-down(md) {
            line-height: 1rem;
        }
    }
}

div[class*='experience'][class*='column'] {
    .hcb-container-main {
        .hcb-video {
            .video-controls {
                top: 30px;
                right: 14px;

                &.aspect1x1 {
                    .video-controls {
                        right: 90px;

                        @include media-breakpoint-down(md) {
                            right: 55px;
                        }
                    }
                }

                &.aspect4x5 {
                    .video-controls {
                        right: 90px;

                        @include media-breakpoint-down(md) {
                            right: 55px;
                        }
                    }
                }

                &.aspect9x16 {
                    .video-controls {
                        right: 90px;

                        @include media-breakpoint-down(md) {
                            right: 55px;
                        }
                    }
                }

                &.aspect5x4 {
                    .video-controls {
                        right: 90px;

                        @include media-breakpoint-down(md) {
                            right: 55px;
                        }
                    }
                }

                &.aspect16x9 {
                    .video-controls {
                        right: 90px;

                        @include media-breakpoint-down(md) {
                            right: 55px;
                        }
                    }
                }
            }
        }

        .hcb-container {
            max-width: 550px;

            &.left {
                text-align: left;
            }

            &.center {
                text-align: center;
                justify-content: center;
            }

            &.right {
                text-align: right;
                justify-content: right;
            }

            &.vbottom {
                top: 50%;

                &.v3 {
                    top: 53%;
                }

                &.v2 {
                    top: 66%;
                }

                &.v1 {
                    top: 75%;
                }
            }

            .body-container,
            .headline-container {
                &.center {
                    width: 100%;
                }
            }

            &.hbc-video {
                padding-left: 2rem;
                padding-right: 2rem;

                .headline-container {
                    line-height: 2.8rem;

                    &.left {
                        width: 100%;
                        text-align: left;
                    }

                    &.right {
                        width: 100%;
                        text-align: right;
                        justify-content: right;
                    }
                }

                .body-container {
                    line-height: 1rem;

                    &.left {
                        width: 100%;
                        text-align: left;
                    }

                    &.right {
                        width: 100%;
                        text-align: right;
                        justify-content: right;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .disclaimer-container {
            font-size: 0.75rem;
            line-height: 1.125rem;

            &.left {
                text-align: left;
            }

            &.center {
                text-align: center;
            }

            &.right {
                text-align: right;

                .disclaimer-box {
                    margin-left: auto;
                }
            }

            &.hbc-video {
                padding-left: 2rem !important;
                padding-right: 2rem !important;
                line-height: 1rem !important;
            }

            .disclaimer-box {
                max-width: 1000px;
            }
        }
    }
}
