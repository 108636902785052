@import "../../../global/colors";
@import "../../../global/variables";
@import 'bootstrap/scss/mixins/breakpoints';

.why-youll-love-it-container {
    .slick-slide {
        padding-right: 2px;
    }

    .why-youll-love-it-subheader {
        color: $gray-blue;
        font-size: 14px;
        line-height: 20px;
    }

    .card {
        overflow: hidden;

        &.slides-3 {
            width: 31.5%;
        }

        &.slides-2 {
            width: 48.5%;
        }

        .card-title {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;

            @include media-breakpoint-up(xl) {
                font-size: 24px;
                line-height: 26px;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 12px;
            }
        }

        .card-body {
            color: $gray-blue;
            font-size: 14px;
            line-height: 20px;

            @include media-breakpoint-up(xl) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        // Needed for visualization on Page Designer
        video {
            height: initial;
        }
    }

    // Overriding the styling from video.scss
    .nb-pd-video {
        .video-controls {
            top: auto;
            bottom: 12px;
            right: 12px;

            @include media-breakpoint-down(md) {
                bottom: 8px;
                right: 8px;
            }
        }
    }

    .nb-video__product {
        padding-bottom: 100%;
    }

    .nb-video__product video {
        object-fit: cover;
    }
}
