@import "bootstrap/scss/mixins/breakpoints";
@import "../../../variables";

.tab-layout {
    .nav-item {
        min-width: rem(110);
        display: inline-block;

        @include media-breakpoint-up(lg) {
            min-width: rem(200);
        }
    }

    .nav-tabs {
        border-bottom: none;
        white-space: nowrap;
        overflow-x: auto;
        text-align: center;
        background: $white-smoke;
        border-radius: rem(4);
        width: fit-content;

        .nav-link {
            font-size: rem(14);
            background: $white-smoke;
            font-weight: $fontweight-light;

            &.active {
                background: $white;
                font-weight: $fontweight-semibold;
                color: $black;
                border-radius: rem(4);
            }
        }
    }
}
