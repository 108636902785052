@import './global/colors';

$max: 1366px;
$portrait: portrait;
$landscape: landscape;

.nb-video {
    &__product {
        position: relative;
        width: 100%;

        video {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    // &__play-button {
    //     all: unset;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     cursor: pointer;
    //     height: 100%;
    //     width: 100%;
    //     background: $black60;
    //     transition: 0.3s;

    //     &::before {
    //         content: '';
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         height: 6rem;
    //         width: 6rem;
    //         background-size: cover;
    //         background-repeat: no-repeat;
    //         transform: translate(-50%, -50%);
    //         background-image: url('../../images/play-button.svg');
    //         z-index: 10;
    //     }

    //     &.hidden {
    //         opacity: 0;
    //     }
    // }
}

.experience-commerce_assets-HeroComponent,
.experience-commerce_assets-textRightImageComponent,
.experience-commerce_assets-textLeftImageComponent {
    .nb-video {
        &__product {
            video {
                height: 85vh;
                width: 100vw;
                background: $black;
                @include media-breakpoint-up(sm) {
                    height: 100vh;
                }
                @media only screen and (orientation: $portrait) and (max-width: $max) {
                    height: calc(100vh - 90px);
                }
                @media only screen and (orientation: $landscape) and (max-width: $max) {
                    height: calc(100vh - 90px);
                }
            }

            video::-webkit-media-controls-panel {
                background-image: none !important;
            }
        }
    }

    // .nb-video__play-button {
    //     background: none;
    //     opacity: 0.8;
    //     right: 0;
    //     left: unset;
    //     z-index: 2;
    //     @include media-breakpoint-up(lg) {
    //         width: 50%;
    //     }

    //     &:hover {
    //         opacity: 1;
    //     }

    //     &::before {
    //         width: 5rem;
    //         height: 5rem;
    //     }
    // }

    .nb-video-non-modal {
        video {
            width: 100%;
            @include media-breakpoint-up(lg) {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        video.frame-height {
            height: auto;
            @include media-breakpoint-up(lg) {
                height: calc(100vh - 140px);
            }
        }

        .frame-height {
            max-height: rem(360);
            @include media-breakpoint-up(lg) {
                max-height: rem(680);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .add-overlay {
            .nb-video__product {
                position: relative;
            }

            .video-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 1;
                background-color: #000;
                pointer-events: none;

                &.bg-white {
                    background-color: #fff;
                }
            }

            .content-text-hero {
                z-index: 2;
            }
        }
    }
}

.experience-component {
    .align-image-center {
        justify-content: center;
    }

    .align-image-right {
        justify-content: end;
    }

    .align-image-left {
        justify-content: start;
    }

    @include media-breakpoint-up(lg) {
        .w-lg-100 {
            width: 100% !important;
        }

        .w-lg-75 {
            width: 75% !important;
        }

        .w-lg-50 {
            width: 50% !important;
        }

        .w-lg-25 {
            width: 25% !important;
        }

        .align-image-lg-center {
            justify-content: center;
        }

        .align-image-lg-right {
            justify-content: end;
        }

        .align-image-lg-left {
            justify-content: start;
        }
    }

    .forced-ratio {
        position: relative;
        width: 100%;
        height: 0;

        &.aspect1x1 {
            padding-top: 100%;
        }

        &.aspect16x9 {
            padding-top: 56.25%;
        }

        &.aspect5x4 {
            padding-top: 80%;
        }

        &.aspect9x16 {
            padding-top: 177.78%;
        }

        &.aspect4x5 {
            padding-top: 125%;
        }

        &.device-ajusted-ratio {
            padding-top: 56.25%;

            @include media-breakpoint-down(md) {
                padding-top: 100%;
            }
        }

        @include media-breakpoint-up(lg) {
            &.mobileonly {
                padding-top: 0 !important;
                height: auto !important;

                video,
                video.frame-height,
                img {
                    position: relative !important;
                }
            }
        }

        @include media-breakpoint-down(md) {
            &.aspect-mob-1x1 {
                padding-top: 100%;
            }

            &.aspect-mob-16x9 {
                padding-top: 56.25%;
            }

            &.aspect-mob-5x4 {
                padding-top: 80%;
            }

            &.aspect-mob-9x16 {
                padding-top: 177.78%;
            }

            &.aspect-mob-4x5 {
                padding-top: 125%;
            }

            &.deskonly {
                padding-top: 0 !important;
                height: auto !important;

                video,
                video.frame-height,
                img {
                    position: relative !important;
                }
            }
        }

        video,
        video.frame-height,
        img {
            height: 100%;
            max-height: none;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            @include media-breakpoint-up(lg) {
                height: 100%;
                max-height: none;
            }
        }
    }
}

// .experience-commerce_assets-textRightImageComponent,
// .experience-commerce_assets-textLeftImageComponent {
//     .nb-video__play-button {

//         @include media-breakpoint-up(lg) {
//             width: 100%;
//         }
//     }
// }
