@import "bootstrap/scss/mixins/breakpoints";
@import '../../../global/variables';
@import '../../../global/colors';
@import '../../../mixins/error';
@import 'bootstrap/scss/variables';
@import "../../../variables";
@import "../../../mixins/util";

.product-tile.product-tile-pd {
    .color-swatches {
        display: none;
        position: relative;
        height: rem(40);

        @include media-breakpoint-down(md) {
            display: none;
        }

        .swatches {
            overflow: hidden;
            white-space: nowrap;
            width: calc(100% - 36px);

            .getproduct {
                width: max-content;
                height: 2.6rem;

                .slides {
                    display: inline-block;

                    &.inactive {
                        display: none;
                    }
                }
            }

            button {
                background-color: transparent;
                border: 0;
                background-size: 1rem;
                width: 1.5rem;
                height: 1.5rem;

                &:disabled {
                    opacity: 0.4;
                    cursor: auto;
                }
            }

            .prev {
                position: absolute;
                top: 46%;
                left: 0;
                z-index: 999;

                button.prev {
                    background-image: url('../images/left-icon.svg');
                    background-repeat: no-repeat;
                }
            }

            .next {
                position: absolute;
                right: -2%;
                top: 28%;

                button.next {
                    background-image: url('../images/right-icon.svg');
                    background-repeat: no-repeat;
                }
            }
        }

        .swatch-circle {
            width: 2.5rem;
            height: 2.5rem;
            border: 0;
        }
    }

    .strike-through {
        color: $dark-grey;
    }

    .content-color-swatches {
        height: rem(40);
    }

    .nb-button.button-secondary.calendarstack-btn {
        margin-top: 10px;
    }

    .badges {
        position: absolute;
        top: 0.375rem;

        .sub-badges {
            color: #fff;
            font-size: 0.875rem;
            line-height: 1rem;
            background-color: #000;
            height: 1.5rem;
        }

        .sub-badges.dailyDeal {
            background-color: $nb-red;
        }
    }

    .tile-badges {
        .sub-badges {
            color: $orange;
            font-size: rem(14);
        }
    }
}

.experience-commerce_assets-CalendarStack {
    .product-grid {
        .font-header-2 {
            margin-top: 15px;

            &:first-child {
                margin-top: 20px;
            }
        }
    }
}

.shopnow-products {
    .product-tile.product-tile-pd {
        .nb-button.button-secondary.calendarstack-btn {
            background-color: #cf0a2c;
            color: #fff !important;

            &:hover {
                background-color: #b90020;
                border-color: #b90020;
            }

            &:focus {
                outline: 2px auto highlight;
                outline: 2px auto -webkit-focus-ring-color;
                box-shadow: none;
            }
        }
    }
}

.outofstock-products {
    .product-tile.product-tile-pd {
        .nb-button.button-secondary.calendarstack-btn {
            display: none;
        }

        .image-container {
            opacity: 0.3;
            transition: opacity 0.7s;

            &:hover {
                opacity: 1;
            }
        }
    }
}

// Select Size Modal
#selectProductSizeModal {
    .modal-header {
        border: none;
        padding: rem(16) 0 rem(32) rem(8);

        h3 {
            font-family: $body-font-family;
            font-size: rem(14);
            color: $black;
            letter-spacing: 0;
            line-height: rem(16);
            font-weight: $fontweight-bold;
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                font-size: rem(16);
                line-height: rem(24);
                font-weight: $fontweight-semibold;
            }
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }

        .close {
            padding: 0.5rem;
            position: absolute;
            top: rem(16);
            right: rem(16);
            z-index: 99;

            @include media-breakpoint-down(md) {
                margin: 0;
                padding: 0 0.5rem;
            }
        }
    }

    .modal-body {
        margin-top: rem(24);
        padding-left: rem(16);
        padding-bottom: 0;

        .product-name {
            margin-top: rem(16);
            margin-bottom: 0;
        }

        .size-chart {
            display: none;
        }

        @include media-breakpoint-down(md) {
            margin-top: 0;
            padding-left: rem(8);
        }
    }

    .modal-footer {
        position: static;
        padding-top: rem(4);

        .cart-and-ipay {
            text-align: left;
            padding: 0 rem(8) rem(16) rem(32);

            .add-to-cart-global {
                width: 100%;
                margin: 0;

                .fa-shopping-bag {
                    display: none;
                }
            }
        }

        @include media-breakpoint-down(md) {
            position: sticky;
            bottom: 0;
            left: 0;
            width: 100%;
            background: $white;
            padding-top: 0;

            .cart-and-ipay {
                padding: rem(16) rem(8);
            }

            .apple-pay-pdp {
                display: none;
            }
        }
    }

    .icon-prev,
    .icon-next {
        border-radius: 2rem;
        padding-top: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .carousel-control-prev {
        left: rem(16);
        color: $white;
    }

    .carousel-control-next {
        right: rem(16);
        color: $white;
    }

    .prices {
        text-align: left;
    }

    .availability-msg {
        font-weight: 700;
        color: $nb-red;
    }

    .attributes {
        @include media-breakpoint-down(xs) {
            .width-attribute {
                min-width: 6.3rem !important;
            }
        }

        [data-attr="style"] {
            margin-bottom: 16px !important; // To override base inline styles
        }

        .attribute {
            div:first-child {
                margin-bottom: 0 !important; // To override base styles & has no proper class
            }

            .attribute-label {
                margin-top: 0 !important; // To override base styles
            }

            .color-display-name {
                margin-top: rem(24) !important; // To override base inline styles

                @include media-breakpoint-down(md) {
                    margin-top: 0 !important; // To override base template inline styles
                    margin-bottom: rem(12);
                }
            }

            .error-msg {
                display: none;
                color: $nb-red;
            }

            &.error {
                .error-msg {
                    display: inline-flex;
                }
            }
        }

        .variation-attribute {
            border: 2px solid $white-smoke;
            padding: 0;
            margin-bottom: 0.2rem;
            background: $white;
            font-size: $font-size14;
            font-weight: 300;
            line-height: 24px;
            min-width: rem(56); // To override with base nb-button styles
        }

        .style-attribute {
            min-width: 3rem;
            height: 3.75rem;
            border: 0;
            border-radius: 0;
            @include media-breakpoint-down(md) {
                width: 4rem;
                height: 4.75rem;
            }
        }

        .style-value {
            width: 3rem;
            height: 3rem;
            display: inline-block;
            overflow: hidden;
            background-size: contain;
            @include media-breakpoint-down(md) {
                width: 4rem;
                height: 4rem;
            }
        }

        .variation-attribute:focus,
        .variation-attribute.selected {
            background: $nb-black;
            color: $white;
        }

        .variation-attribute:not(.style-attribute).unselectable {
            color: $boulder;
            background: $white-smoke;
            pointer-events: none;
        }

        .style-attribute {
            &.fully-oos-ind {
                color: $boulder;
                background: $white;
                pointer-events: none;
            }
        }

        .style-attribute:focus,
        .style-attribute.selected {
            background: linear-gradient($white 3.5rem, $brick-red 0.25rem);
            @include media-breakpoint-down(md) {
                background: linear-gradient($white 4.5rem, $brick-red rem(4));
            }
        }

        .non-input-label {
            font-size: $font-size16;
            font-weight: $fontweight-semibold;
        }

        .size-attribute {
            height: 3.5rem;
            border: 2px solid $white-smoke;
            background: $white;
            margin-right: 8px !important;
            margin-bottom: 7px !important;
        }

        .width-attribute {
            min-width: 7.5rem;
            height: 3rem;
            margin-right: rem(8) !important;
        }
    }

    .update-cart-product-global {
        width: 100% !important;
        margin: 0 !important;
    }

    .primary-images {
        .carousel {
            img {
                width: 100%;
                height: auto;
            }

            .carousel-control-prev,
            .carousel-control-next {
                display: flex;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .carousel-indicators {
                bottom: -45px;

                li {
                    display: inline-block;
                    width: 0.3rem;
                    height: 0.3rem;
                    border-radius: 50%;
                    background-color: $rolling-stone;

                    &.active {
                        background-color: $boulder;
                    }
                }
            }
        }
    }
}

.experience-commerce_assets-VerticalProductCarousel {
    .product-tile:has(.tile-badges) {
        .tile-badges {
            margin-bottom: -0.5rem !important;
        }
    }
}
