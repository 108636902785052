@import "../../../global/colors";
@import "../../../global/variables";

.whitespace-size-1 { height: 1px; }
.whitespace-size-2 { height: 2px; }
.whitespace-size-3 { height: 4px; }
.whitespace-size-4 { height: 8px; }
.whitespace-size-5 { height: 16px; }
.whitespace-size-6 { height: 32px; }
.whitespace-size-7 { height: 48px; }
.whitespace-size-8 { height: 96px; }

.whitespace-bg-white { background-color: #fff; }
.whitespace-bg-black { background-color: #000; }
.whitespace-bg-nb-black { background-color: #151415; }
.whitespace-bg-grey-1 { background-color: #f8f9f9; }
.whitespace-bg-grey-2 { background-color: #efefef; }
.whitespace-bg-dark-grey { background-color: #737b82; }
.whitespace-bg-red { background-color: #cf0a2c; }
