
//NB font family- updated
$nb-page-heading-font : "ITC Garamond W01";
$linotype-didotw01-n3 : "Linotype Didot W01_n3";
$linotype-didot-w01-i3 : "Linotype Didot W01_i3";
$linotype-didot-w01-n7 : "Linotype Didot W01_n7";
$body-font-family : "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; // used for "Gotham HTF medium"
$proxima-nova-w01-n2 : "Proxima Nova W01_n1", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n3 : "Proxima Nova W01_n3", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n4 : "Proxima Nova W01_n4", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n5 : "Proxima Nova W01_n5", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n6 : "Proxima Nova W01_n6", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n7 : "Proxima Nova W01_n7", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n8 : "Proxima Nova W01_n8", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
