.experience-einstein-einsteinRecommenderCarousel,
.experience-einstein-einsteinRecommenderCarouselProduct,
.experience-einstein-einsteinRecommenderCarouselCategory {
    .header {
        @include media-breakpoint-up(lg) {
            padding: 0 40px !important;
        }

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    .einstein-horizontal-product-carousel {
        @include media-breakpoint-up(lg) {
            padding: 18px 0 !important;
        }
    }

    .carousel-inner {
        @include media-breakpoint-down(md) {
            overflow: visible;
        }
    }

    .horizontal-product-carousel {
        &.slick-initialized {
            @include media-breakpoint-down(md) {
                margin-left: -2px;
                margin-right: -8px;
                margin-bottom: 0;
            }
        }
    }

    .carousel-ctn:has(.slick-initialized) {
        @include media-breakpoint-down(md) {
            margin-left: -8px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: -8px;
            margin-right: -8px;
        }
    }

    .carousel-ctn:not(:has(.slick-initialized)) {
        .header {
            margin-left: -8px;
        }
    }
}

.experience-commerce_layouts-Tabs {
    .carousel-inner {
        overflow: visible;
    }

    .tabbed-einstein-carousel:has(.slick-initialized) {
        @include media-breakpoint-down(md) {
            margin-left: -2px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: -8px;
            margin-right: -8px;
        }
    }

    .tabbed-carousel {
        &.slick-initialized {
            @include media-breakpoint-down(md) {
                margin-bottom: 4px;
            }
        }
    }

    .recommender-carousel-cont:not(:has(.product-tile)) + .slick-scrollbar-cta {
        bottom: 0;
    }
}
