@import "../global/variables";
@import "bootstrap/scss/mixins/breakpoints";

#footercontent {
    background-color: $white;
    padding-bottom: 0; // overriding storefront
    overflow-x: hidden;

    @include media-breakpoint-down(md) {
        &.checkout-footer {
            border-top: rem(1) solid $silver-grey;
        }
    }

    .footer-width {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .add-links {
        a {
            color: $black !important;
        }
    }

    .find-store {
        color: $nb-black;
    }

    .email-signup {
        width: 100%;
        background-color: $nb-black;
        margin: 0;
        padding: rem(20);
        box-sizing: border-box;

        .email-content-text {
            display: inline-block;
            color: $white;
            vertical-align: middle;
            line-height: 1.5;

            @include media-breakpoint-down(md) {
                width: 10rem;
                font-size: $font-size16;
                font-weight: $fontweight-bold;
                line-height: rem(21);
            }
        }
    }

    .footer-columns {
        margin-top: rem(48);
        margin-bottom: rem(40);
        color: $nb-black;
        font-size: 1rem;
        line-height: 2rem;

        @include media-breakpoint-down(md) {
            margin: auto;

            .column-content {
                margin: 0.7rem auto rem(40);

                .footer-item {
                    border-bottom: none;
                    padding: rem(4) rem(8);
                }
            }
        }

        .footer-item {
            @include media-breakpoint-down(md) {
                border: 0;
                padding: 0 0 2rem;

                .logo {
                    img {
                        width: rem(59);
                        height: rem(28);
                    }
                }

                .social-icons {
                    margin-top: rem(24) !important;
                }
            }

            .content {
                font-size: $font-size14;
            }
        }

        .logo {
            max-width: rem(56);
        }

        .title {
            color: $black;
            font-size: $font-size14;
            font-weight: $fontweight-bold !important;
            padding-bottom: rem(14);
            padding-top: rem(18);
            padding-right: 0;
            line-height: 1rem;
        }

        ul {
            li {
                height: auto;
            }
        }
    }

    .footer-grey-box {
        background-color: $alabaster;
        padding-bottom: 1rem;

        .familybrands-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: rem(24) 0;

            .title {
                width: auto;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            img {
                height: 2rem;
            }

            @include media-breakpoint-down(md) {
                margin: 2rem auto 1rem;
                padding: 1rem;
                flex-wrap: wrap;
                justify-content: left;

                img {
                    height: rem(24);
                }
            }
        }

        .familybrands-content > * {
            margin-right: rem(24);

            @include media-breakpoint-down(md) {
                margin: 0.5rem 1rem 0.5rem 0;
            }
        }

        .footer-additional-links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: $font-14px;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

            .footer__aditional__container {
                display: flex;
                flex-direction: row;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }
            }

            .find-store {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }

                .select-country {
                    .country-selector-one {
                        padding: rem(0);
                    }

                    .country-img {
                        display: flex;
                        justify-content: flex-end;

                        img {
                            height: rem(16);

                            @include media-breakpoint-up(md) {
                                height: rem(20);
                            }
                        }
                    }

                    .country-name {
                        color: $black;
                    }
                }
            }

            .footer__aditional__container > div.add-links {
                margin: 0 1rem;

                @include media-breakpoint-down(md) {
                    margin: 0.5rem 0 0;
                }
            }

            .additional-links {
                .add-links {
                    @include media-breakpoint-down(md) {
                        padding-left: 0.95rem;
                        line-height: 1.6rem;
                        font-size: $font-14px;
                        margin-bottom: rem(16);
                    }

                    a {
                        font-size: 0.8rem;
                        white-space: nowrap;
                        text-decoration: underline;

                        @include media-breakpoint-down(md) {
                            font-size: 0.7rem;
                        }
                    }
                }
            }

            .copy {
                font-size: 0.75rem;
                text-align: right;

                @include media-breakpoint-down(md) {
                    text-align: left;
                    margin-top: 2rem;
                    font-size: 0.75rem;
                }
            }
        }
    }

    .custom_footer_h2 {
        font-family: $body-font-family;
        line-height: 1rem;
        font-size: 0.875rem;
        font-weight: $fontweight-bold;
    }

    .footer-image {
        max-height: rem(20);
    }

    .didot-header {
        font-family: $nb-page-heading-font;
    }
}

// Checkout footer styles
.checkout-footer {
    color: $black !important;

    @include media-breakpoint-down(md) {
        padding-top: 0;
    }

    .has-sticky-footer & {
        @include media-breakpoint-down(md) {
            margin-bottom: rem(136); // to scroll through sticky footer
        }
    }

    &.place-order-stage {
        @include media-breakpoint-down(md) {
            margin-bottom: rem(146); // to scroll through sticky footer
        }
    }

    @include media-breakpoint-up(md) {
        .needhelp {
            border-top: 1px solid $silver-grey;
        }
    }

    .accept-cards-label {
        white-space: nowrap;
    }

    .support-text {
        font-size: $font-size14;
        line-height: $font-size20;
        color: $black;
    }

    .c-faq,
    .email {
        font-size: $font-size14;
        font-weight: $fontweight-bold;
        line-height: $font-size20;
        color: $black !important;
    }

    .telephone {
        font-size: $font-size14;
        line-height: $font-size20;

        a {
            color: $black !important;
        }
    }

    .cards {
        font-size: $font-size14;
        font-weight: $fontweight-bold;
        line-height: $font-size16;
    }

    .copyright {
        font-size: $font-size12;
        font-weight: $fontweight-regular;
        line-height: $font-size24;
    }

    .copy-right-container {
        @include media-breakpoint-down(md) {
            margin-bottom: 1rem !important;
        }
    }
}

.backtotop {
    display: none;
    position: fixed;
    bottom: 24%;
    right: 5%;
    cursor: pointer;

    .goback {
        display: inline-block;
        width: 24px;
        height: 24px;
    }
}

.afterpay-widget {
    .afterpay-image {
        width: 4.75rem;
        height: 2.5rem;
    }
}

#afterpay-tooltip-show {
    .close-button {
        font-size: $font-size48;
    }
}

.globalModal {
    .modal-dialog {
        @include media-breakpoint-up(md) {
            width: rem(552);
            max-width: rem(552);
        }
    }

    .modal-content {
        position: relative;

        @include media-breakpoint-up(md) {
            padding: 0;
        }

        .close {
            position: absolute;
            right: rem(16);
            padding: rem(16);
        }

        .modal-body {
            margin-top: rem(81);

            @include media-breakpoint-up(md) {
                margin-top: rem(120);
                padding: 0;
            }

            .email-content,
            .privacy-policy {
                @include media-breakpoint-up(md) {
                    margin-left: rem(72);
                    margin-right: rem(24);
                }
            }

            .email-content {
                input::placeholder {
                    color: #000;
                }
            }

            .content {
                .content-asset {
                    .w-100 {
                        @include media-breakpoint-up(md) {
                            margin-left: rem(72) !important;
                            margin-right: rem(24) !important;
                            width: 368px !important;
                        }
                    }

                    .subscribe-title {
                        @include media-breakpoint-up(md) {
                            width: rem(392);
                            margin-left: rem(72);
                            margin-right: rem(88);
                            padding-left: rem(30);
                            padding-right: rem(30);
                        }
                    }
                }
            }
        }
    }
}

.store-locator-bottom-content .store {
    #store-content {
        width: rem(275);
    }

    #store-content-sec {
        width: rem(282);
    }
}
