@import "bootstrap/scss/mixins/breakpoints";
@import "../../../variables";
@import "../../../global/spacers";

$xl: 1440px;

.grid-tile {
    .spacer-video-text-ctn {
        position: relative;
        overflow: hidden;

        .spacer-element {
            padding-top: 157%;
        }

        .video-and-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            figcaption.component-content div {
                margin: 0 auto;
                padding: 0;

                .headline,
                .font-body-large {
                    margin-bottom: 1rem;
                }

                @include media-breakpoint-down(md) {
                    .headline {
                        margin-bottom: rem(10);
                    }

                    .font-body {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .grid-tile-video-container {
        height: 100%;
        width: 100%;

        .video-controls {
            top: 0;
            right: 0;

            .play-pause {
                button.btn-play,
                button.btn-pause {
                    margin-left: 0;
                    padding: 8px;

                    img {
                        @include media-breakpoint-down(md) {
                            height: unset;
                            left: unset;
                        }
                    }
                }
            }
        }

        .grid-tile-video-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
            pointer-events: none;
            z-index: 0;

            &.bg-white {
                background-color: #fff;
            }
        }

        video {
            object-fit: cover;
        }
    }

    .component-content {
        bottom: 0;
        height: 100%;

        &.one-tile-wide {
            .headline,
            .font-body-large {
                margin-bottom: 1rem;
            }

            @include media-breakpoint-down(md) {
                .headline {
                    margin-bottom: rem(10);
                }

                .font-body {
                    margin-bottom: rem(10);
                }
            }

            @include media-breakpoint-up(lg) {
                .component-btn {
                    margin-bottom: calc(0.5rem + ((1vw - 9.92px) * 2.9018));
                }

                .disclaimer {
                    margin-bottom: calc(0.25rem + ((1vw - 9.92px) * 2.6786));
                }
            }
            @media screen and (min-width: $xl) {
                .headline {
                    margin-bottom: $nb-spacing-2;
                }

                .component-btn {
                    margin-bottom: rem(21);
                }

                .disclaimer {
                    margin-bottom: $nb-spacing-3;
                }
            }
        }

        &.two-three-tile-wide {
            margin-bottom: $nb-spacing-6;

            @include media-breakpoint-up(lg) {
                bottom: 0;
                height: 100%;
            }
        }

        .offset-1 {
            margin: 0 auto;
            padding: 0;
        }

        left: 8px;
        right: 8px;
    }

    .component-overlay {
        top: 0;
        left: 0;
    }

    .headline {
        font-size: calc(1.4rem + ((1vw - 3.75px) * 3.5714));
        line-height: $line-height-s-grid-heading;
        @include media-breakpoint-up(lg) {
            font-size: calc(1.4rem + ((1vw - 9.92px) * 4.9107));
        }
        @media screen and (min-width: $xl) {
            font-size: $font-size44;
        }
    }

    .font-body-large {
        @include media-breakpoint-up(lg) {
            margin-bottom: calc(0.625rem + ((1vw - 9.92px) * 1.3393));
        }
        @media screen and (min-width: $xl) {
            margin-bottom: $nb-spacing-3;
        }
    }

    .component-btn {
        position: relative;
        z-index: 1;
        text-align: left;
        line-height: 20px;
        @include media-breakpoint-down(lg) {
            margin-bottom: 1em;
        }
    }

    .component-disclaimer {
        @include media-breakpoint-up(lg) {
            bottom: calc(0.3125rem + ((1vw - 9.92px) * 5.5804));
        }
        @media screen and (min-width: $xl) {
            bottom: rem(30);
        }
    }
}
