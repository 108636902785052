@import '../../../global/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.guided-buying {
    column-gap: 1rem;
    @include media-breakpoint-up(lg) {
        column-gap: 0.5rem;
    }

    .gb-tile {
        flex-grow: 1;
        flex-basis: 100%;

        .tile-img {
            object-fit: cover;
            max-height: 250px;
            position: relative;
            border-radius: 8px;
            @include media-breakpoint-up(lg) {
                max-height: 450px;
            }
        }

        .arrow-right {
            display: inline-block;
            width: 14px;
        }

        .gb-tile-text-block {
            &.two-tiles {
                @include media-breakpoint-up(lg) {
                    width: 50%;
                }
            }

            &.three-tiles {
                @include media-breakpoint-up(lg) {
                    width: 70%;
                }
            }

            .link-text {
                overflow: hidden;
                position: relative;

                --lh: 1.4rem;
                --max-lines: 2;

                max-height: calc(var(--lh) * var(--max-lines));
            }

            .link-text::after {
                content: "";
                text-align: right;
                position: absolute;
                bottom: -13px;
                right: 0;
                width: 100%;
                height: 1.2em;
                background: white;
            }

            .link-subtext {
                overflow: hidden;
                position: relative;

                --lh: 1.4rem;
                --max-lines: 2;

                max-height: calc(var(--lh) * var(--max-lines));
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 14px !important;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        border-radius: 0;
        background: unset;

        li {
            position: relative;
            display: inline-block;
            margin: 0 !important;
            width: 15px !important;
            height: 15px !important;
            padding: 0;
            cursor: pointer;

            button {
                font-size: 0;
                line-height: 0;
                display: block;
                width: 15px !important;
                height: 15px !important;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: transparent;

                &:hover,
                &:focus {
                    &::before {
                        opacity: 1;
                    }
                }

                &::before {
                    font-family: 'slick';
                    font-size: 30px;
                    line-height: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    content: '•';
                    text-align: center;
                    opacity: 0.4;
                    color: black;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }

            &.slick-active {
                background: unset;
                border-radius: 0;
            }

            &.slick-active button::before {
                opacity: 1;
                color: black;
            }
        }
    }
}

.enhanced-guided-buying-wrapper {
    .slick-scrollbar-cta {
        bottom: -5px;
    }
}

.enhanced-guided-buying {
    overflow: hidden;
    column-gap: 0.2rem;

    @include media-breakpoint-up(lg) {
        column-gap: 0.5rem;
    }

    @include media-breakpoint-down(sm) {
        margin-right: 2px;
    }

    .headline-text {
        font-weight: 300 !important;
        font-size: 1.5rem !important;
        line-height: 2rem;
    }

    .slide-arrow {
        transform: translate(50%, 50%);
    }

    .prev-arrow,
    .next-arrow {
        background-color: rgba(255, 255, 255, 0.75);

        &::after {
            filter: invert(0);
        }
    }

    .slick-list {
        min-width: 100%;
    }

    .slick-track {
        display: flex;
        column-gap: 0.3rem;

        .slick-slide {
            @include media-breakpoint-up(lg) {
                padding-right: 0;
            }
        }
    }

    .gb-tile {
        flex-grow: 1;
        flex-basis: 100%;
        position: relative;
        justify-content: center;
        align-items: center;

        a {
            display: block;
        }

        .tile-img {
            z-index: 1;
            border-radius: 0 !important;
            object-fit: cover;
            position: relative;
        }

        .arrow-right {
            display: inline-block;
            width: 14px;
        }

        .gb-tile-text-block {
            z-index: 2;

            @include media-breakpoint-up(lg) {
                width: 100%;
            }

            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 1rem;

            &.two-tiles {

                @include media-breakpoint-up(lg) {
                    width: 50%;
                }
            }

            &.three-tiles {

                @include media-breakpoint-up(lg) {
                    width: 70%;
                }
            }

            .link-text {
                margin: 0;
                font-size: 1.5rem;
                font-family: $nb-page-heading-font;

                @include media-breakpoint-down(sm) {
                    font-size: 1rem;
                }

                font-weight: bold;
                color: #fff;
            }

            .link-text::after {
                content: none;
            }

            .link-subtext {
                text-align: center;
            }
        }

        .subtext {
            color: $gray-blue;
            line-height: 1.25rem;

            @include media-breakpoint-down(sm) {
                font-size: 0.75rem;
            }
        }

        a,
        .subtext {
            position: relative;
        }
    }
}
