@import '../../../global/variables';
@import 'bootstrap/scss/mixins/breakpoints';

$xl-screen: 1246px;
$xxl-screen: 1440px;

.multi-column-layout {
    &.pb-05 {
        padding-bottom: rem(2);
    }

    &.pb-4-5 {
        padding-bottom: rem(32);
    }

    @include media-breakpoint-up(md) {
        .col-12 {
            &.pr-sm {
                padding-right: rem(2) !important;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .col-12 {
            padding-bottom: rem(2) !important;
        }
    }

    .card {
        overflow: hidden;
    }

    .hcb-container-main {
        margin: auto;
    }
}
