@import "bootstrap/scss/mixins/breakpoints";
@import "../../../variables";
@import "../../../mixins/util";

.two-column-text {
    margin: auto;
    @include media-breakpoint-up(lg) {
        .description {
            column-count: 2;
            column-gap: rem(88);
        }
    }
}
