@import "bootstrap/scss/mixins/breakpoints";
@import "../../../variables";
@import "../../../global/theme";
$xl: 1440px;

$section-gap-top: 15px;
$section-gap-bottom: 15px;
$section-gap-side: 10px;

$grid-row-gap: 10px;
$grid-column-gap: 20px;

$element-border-radius: 0.5rem;
$element-small-border-radius: 5px;

.product-comparison-title {
    margin-bottom: 2rem;
    margin-top: 4rem;

    h2 {
        font-size: 3rem;
    }

    a {
        font-size: 1rem;
    }
}

.product-comparison-container {
    margin-top: 2em;
    margin-bottom: 2em !important;

    .product-detail-select {
        position: relative;

        &::after {
            content: "";
            pointer-events: none;
            position: absolute;
            background-image: url(../../images/down-icon.svg);
            background-size: cover;
            top: 50%;
            right: 15px;
            height: 15px;
            width: 15px;
            transform: translateY(calc(-50% - 5px));
        }
    }
}

.product-comparison-select {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    row-gap: $grid-row-gap;
    column-gap: $grid-column-gap;
    min-width: 25%;
}

.comparison-select {
    display: block;
    width: 100%;
    border-color: $nb-alto-grey;
    border-radius: $element-border-radius;
    padding: $section-gap-top 33px $section-gap-bottom $section-gap-side;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 700;
    max-width: calc(100% + 0);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: $section-gap-bottom;

    option {
        background-color: $white-smoke;
    }
}

.product-comparison-detail {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    row-gap: $grid-row-gap;
    column-gap: $grid-column-gap;
    min-height: 600px;

    .product-detail-item {
        border-style: dashed;
        border-color: $nb-alto-grey;
        border-radius: $element-border-radius;
        vertical-align: middle;
        position: relative;
        border-width: 2px;
        min-width: 25%;

        &.no-dash {
            border-style: none;
        }

        .empty-panel {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            color: $nb-grey;
            font-size: 1rem;
            text-align: center;
            line-height: 1;
        }
    }

    .product-bestfor {
        font-size: medium;
        font-weight: 700;
        padding: 0 $section-gap-side $section-gap-bottom;
        text-align: center;
        width: 100%;
        min-height: 4rem;

        .headline-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
        }
    }

    .tile-image {
        width: 100%;
        padding: 0 0 $section-gap-bottom;
        object-fit: contain;

        &::after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $white;
        }
    }

    .product-pill-container {
        .pill-header {
            text-align: center;
            margin-bottom: 0.3rem;
            font-size: 1rem;
        }

        .pills {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 5px;

            .pill {
                height: 10px;
                background-color: $white;
                border: $black solid 1px;
                color: $white;
                border-radius: 50px;

                &.filled {
                    background-color: $black;
                }
            }
        }
    }

    .comparison-tile {
        .product-technology,
        .product-drop,
        .product-weight {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
            column-gap: $grid-column-gap;
            padding: $section-gap-top $section-gap-side $section-gap-bottom;

            .tech-img {
                height: 2.5rem;
                max-width: 60%;
                min-width: 5rem;
                margin: -$section-gap-top $section-gap-side -$section-gap-bottom;
            }

            .info-svg {
                height: 1.2rem;
                border: 1px solid $black;
                border-radius: 1rem;
                width: 1.2rem;
                padding: 2px 4px;
                margin-left: 10px;
            }

            .left-panel {
                p {
                    margin: 0;
                    font-size: 1rem;
                }
            }

            .right-panel {
                position: relative;
                text-align: right;
                white-space: nowrap;
            }
        }

        .product-bestfor,
        .product-technology,
        .product-drop,
        .product-weight {
            p {
                margin: 0;
            }
        }

        .product-technology {
            .right-panel {
                white-space: nowrap;

                .empty-section {
                    &:hover {
                        cursor: pointer;
                    }
                }

                &:hover {
                    cursor: pointer;
                }

                .t-tip {
                    position: absolute;
                    width: 225px;
                    height: 120px;
                    background-color: $white;
                    border: 1px solid $nb-alto-grey;
                    padding: 5px 15px;
                    text-align: center;
                    font-size: 0.8rem;
                    display: none;
                    align-items: center;
                    justify-content: center;
                    top: -125px;
                    right: -20px;
                    border-radius: $element-small-border-radius;
                    white-space: normal;

                    p {
                        margin: 0;
                    }

                    &::after {
                        content: "";
                        width: 12px;
                        height: 12px;
                        position: absolute;
                        bottom: -7px;
                        right: 23px;
                        border: 1px solid $nb-alto-grey;
                        transform: rotate(45deg);
                        background-color: $white;
                        border-top: none;
                        border-left: none;
                    }
                }
            }
        }
    }

    .product-cushion,
    .product-technology,
    .product-drop,
    .product-weight {
        border-bottom: 1px solid $nb-alto-grey;
    }

    .product-price {
        padding: $section-gap-top $section-gap-side $section-gap-bottom;
    }

    .product-shop-now {
        a {
            width: 60%;
            border-radius: $element-small-border-radius;
            height: unset;
        }
    }

    .empty-section {
        display: grid;
        column-gap: $grid-column-gap;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        height: 1.5rem;

        .no-data {
            height: 2px;
            border: $nb-alto-grey solid 1px;
        }
    }

    .product-stability,
    .product-cushion {
        padding: 0 $section-gap-side $section-gap-bottom;

        .empty-section {
            height: calc(2rem + 7px);
        }
    }
}

@include media-breakpoint-down(lg) {
    .comparison-tile {
        .product-technology,
        .product-drop,
        .product-weight {
            .empty-section {
                column-gap: 5px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .product-comparison-title {
        h2 {
            font-size: 2rem;
        }
    }

    .product-comparison-select,
    .product-comparison-detail {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;

        .product-item-3 {
            display: none;
        }

        .product-item-4 {
            display: none;
        }

        .product-stability,
        .product-cushion {
            .empty-section {
                height: 2.4rem;
            }
        }

        .product-technology,
        .product-drop,
        .product-weight {
            .empty-section {
                height: 1.2rem;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .product-comparison-detail {
        .product-stability,
        .product-cushion {
            text-align: center;

            .empty-section {
                height: calc(2rem + 2px);
            }
        }

        .comparison-tile {
            .product-pill-container .pill-header {
                font-size: 0.8rem;
            }

            .product-bestfor {
                padding: 10px 5px !important;
            }

            .product-bestfor,
            .product-technology,
            .product-drop,
            .product-weight {
                column-gap: 0;
                padding: 15px 5px;
                font-size: 0.8rem;

                .left-panel,
                .right-panel {
                    p {
                        font-size: 0.8rem;
                    }
                }

                .right-panel .t-tip {
                    width: 165px;
                    z-index: 1;
                    right: -4px;

                    &::after {
                        right: 4px;
                    }
                }

                .empty-section {
                    height: 1.2rem;
                }
            }

            .product-weight {
                grid-template-columns: 1fr  4fr;
            }

            .product-technology {
                .tech-img {
                    min-width: 4.5rem;
                }

                .info-svg {
                    height: 0.9rem;
                    width: 0.9rem;
                    margin-left: 0;
                }
            }
        }
    }
}
