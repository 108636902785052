.myfit,
.account-profile {
    .fit-body-not-set {
        margin-top: rem(7);
    }

    .fit-links {
        font-weight: $fontweight-semibold;
        $color: $black !important;

        a {
            text-decoration: underline;
        }
    }

    .fit-body-set {
        margin-top: rem(7);
        color: $black !important;

        .fit-row {
            margin-bottom: rem(19);
        }

        .fit-data {
            margin-bottom: rem(12);
        }
    }
}

.account-profile {
    .profile-firstname {
        margin-right: rem(100);
    }

    .profile-phone {
        margin-right: rem(20);
    }
}

.order-history {
    .dot {
        width: rem(21);
        height: rem(21);
        border: solid;
        border-radius: 50%;

        @include media-breakpoint-down(md) {
            display: inline-table;
        }
    }

    .dot-link {
        height: rem(2);
        width: 2.4rem;
        background-color: $black;
        margin-top: 0.5rem;
    }

    .mr-auto {
        margin: 0 auto;
    }

    .dot-container {
        width: 6rem;
        @include media-breakpoint-down(md) {
            width: 5.6rem;
        }
    }

    .dot-number {
        color: $nb-black;
        font-family: $body-font-family;
        font-size: rem(10);
        font-weight: $fontweight-semibold;
        letter-spacing: 0;
        line-height: 1rem;
        text-align: center;
    }

    .dot-number-cancelled {
        background-color: yellow;
    }

    .order-text {
        font-size: rem(12);
        line-height: 1rem;
    }

    .text-link {
        text-decoration: underline;
    }

    .text-bold {
        font-weight: $fontweight-bold;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .activeDot {
        background: $black;
        color: $white;
        border-color: $black;
    }

    .pointer {
        cursor: pointer;
    }
}

.order-status {
    max-width: rem(993);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $nb-spacing-11+$nb-spacing-4;
    @include media-breakpoint-down(md) {
        margin-bottom: $nb-spacing-11;
    }
}

.guest-invoice-dowload {
    max-width: rem(993);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $nb-spacing-11+$nb-spacing-4;
    @include media-breakpoint-down(md) {
        margin-bottom: $nb-spacing-11;
    }
}
