@import '../../../global/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.overlay-image-container {
    z-index: 1;
    max-width: rem(57);
    margin: 1rem;
    @include media-breakpoint-up(lg) {
        max-width: rem(70);
    }
}
