@import "bootstrap/scss/mixins/breakpoints";
@import "../../../variables";
@import "../../../global/theme";

.shop-the-look {
    background-color: white;
    @include media-breakpoint-up(lg) {
        padding-left: 16px;

        h3 {
            font-size: 1.5rem;
        }
    }

    .stylitics-desktop-modal {
        z-index: 100 !important;
    }

    .stylitics-bundle-gallery {
        margin-bottom: 2.5rem !important;
    }
}

.stylitics-sequence::-webkit-scrollbar {
    display: none;
}

.stylitics-item-container {
    padding-bottom: 25px;
}

.stylitics-item-name {
    font-family: $body-font-family;
    font-weight: 600 !important;
    color: black !important;
    font-size: $font-size16 !important;
}

.stylitics-view-item-details-cta {
    font-weight: 700;
}

.stylitics-item-price-and-cta {
    font-family: $body-font-family;
    color: black;
    padding-top: 12px;
}

.stylitics-item-regular-price {
    font-size: $font-size16 !important;
}

.stylitics-item-sale-price {
    font-size: $font-size16 !important;
}

.stylitics-cta-link {
    font-size: $font-size14 !important;
}

.stylitics-jump-link-container {
    right: 2%;
    z-index: 100;
    display: flex;
    background-color: rgba($nb-black, 0.8);

    .stylitics-jump-link {
        color: $white;
        padding-right: 12px;

        span {
            line-height: 14px;
            position: relative;
            font-size: $font-size14;

            @include media-breakpoint-down(md) {
                line-height: 16px;
                font-size: $font-size12;
            }
        }

        &:hover {
            text-decoration: none !important;
        }
    }
}

.full-gallery {
    .stylitics-gallery-container {
        padding: 0 !important;

        .stylitics-bundle-gallery {
            padding: 0 !important;

            .stylitics-card {
                margin-bottom: 2rem !important;

                .stylitics-bundle-container,
                .stylitics-bundle-container img {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;

                    .stylitics-footer-hide {
                        display: flex;
                    }
                }

                .stylitics-product-list-click {
                    position: static;
                }

                .stylitics-view-item-details-cta {
                    width: 101% !important;
                    border-top: none !important;
                    border: 1px solid #efefef !important;
                    border-radius: 0 0 5px 5px !important;
                    color: black !important;
                }

                .stylitics-view-item-details-cta-gallery {
                    max-width: none !important;
                }

                .stylitics-bundle-details {
                    .stylitics-view-item-details-cta {
                        transform: none !important;
                        color: black !important;
                    }
                }
            }
        }
    }

    .stylitics-cta-link {
        text-transform: none !important;
        color: black !important;
    }
}

.gallery-single-card {
    .stylitics-gallery-base {
        padding-bottom: 15px;

        .stylitics-gallery-container {
            padding: 0 !important;
        }

        .stylitics-bundle-gallery {
            padding: 0 0 2.4rem !important;
            border: 1px solid #efefef !important;
            border-radius: 5px !important;

            div.stylitics-card {
                .stylitics-bundle-container {
                    border-radius: 5px 5px 0 0;
                    padding-bottom: 0;
                    border: 0;

                    .stylitics-main-image {
                        img {
                            border-radius: 5px 5px 0 0;
                        }
                    }

                    .stylitics-footer-hide {
                        display: flex;
                    }

                    .stylitics-bundle-footer {
                        margin-top: 0;
                    }
                }

                .stylitics-product-list-click {
                    width: 100%;

                    .stylitics-view-item-details-cta-gallery {
                        max-width: none;
                    }
                }

                button.stylitics-view-item-details-cta {
                    width: 100% !important;
                    border-top: 1px solid #efefef !important;
                    border: 0;
                    border-radius: 0;
                    transform: none !important;
                    padding-top: 7px;

                    @include media-breakpoint-up(lg) {
                        padding-top: 5px;
                    }

                    span {
                        text-transform: none !important;
                        color: black !important;
                    }
                }
            }
        }
    }

    .stylitics-cta-link {
        text-transform: none !important;
        color: black !important;
    }
}

.shop-the-look-gallery-carousel {
    div[id^='stylitics-gallery-carousel-widget-container-'] {
        .stylitics-inner {
            padding-bottom: 1rem;
        }

        .stylitics-bundle-container {
            padding: 0;
        }

        .stylitics-arrow-btn {
            background-color: rgba(0, 0, 0, 0.6) !important;
            border-radius: 5px;
            height: 75px;
            top: 48% !important;
        }

        .stylitics-next {
            right: 50px !important;

            @include media-breakpoint-down(sm) {
                right: 32px !important;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .stylitics-previous {
            left: 50px !important;

            @include media-breakpoint-down(sm) {
                left: 32px !important;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .stylitics-arrow-btn svg path {
            stroke: white;
        }

        .stylitics-card {
            margin-bottom: 2rem !important;

            .stylitics-bundle-container,
            .stylitics-bundle-container img {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                .stylitics-footer-hide {
                    display: flex;
                }
            }

            .stylitics-product-list-click {
                position: static;
            }

            .stylitics-view-item-details-cta {
                width: 101% !important;
                border-top: none !important;
                border: 1px solid #efefef !important;
                border-radius: 0 0 5px 5px !important;
                color: black !important;
            }

            .stylitics-view-item-details-cta-gallery {
                max-width: none !important;
            }

            .stylitics-bundle-details {
                .stylitics-view-item-details-cta {
                    transform: none !important;
                    color: black !important;
                }
            }
        }

        .stylitics-cta-link {
            text-transform: none !important;
            color: black !important;
        }

        .stylitics-carousel-pips {
            padding-top: 0.5rem !important;

            .stylitics-dots-li {
                padding: 15px 0 !important;
                transform: none !important;

                .stylitics-dot-inner {
                    width: 5vw !important;
                    height: 5px !important;
                    border-radius: unset !important;

                    @include media-breakpoint-down(md) {
                        width: 8vw !important;
                    }
                }

                &:first-child {
                    .stylitics-dot-inner {
                        border-top-left-radius: 20px !important;
                        border-bottom-left-radius: 20px !important;
                    }
                }

                &:last-child {
                    .stylitics-dot-inner {
                        border-top-right-radius: 20px !important;
                        border-bottom-right-radius: 20px !important;
                    }
                }
            }
        }

        .stylitics-sequence {
            padding-left: 1px !important;
            padding-right: 1px !important;
            scrollbar-width: none !important;

            @include media-breakpoint-down(md) {
                padding-bottom: 0 !important;
            }
        }

        .stylitics-bundle-footer {
            text-align: center !important;
        }
    }
}

$locales: 'AT', 'BE', 'DE', 'DK', 'ES', 'FI', 'FR', 'GB', 'IE', 'IT', 'LT', 'LU', 'LV', 'NO', 'NL', 'PT', 'SE', 'SI', 'ZA';

div[id^="stylitics-container-"] {
    @each $locale in $locales {
        &[data-locale$=#{$locale}] {
            .stylitics-ui-item-info-price,
            .stylitics-ui-price {
                display: none;
            }
        }
    }
}

div[id^="stylitics-widget-container-"] {
    img.stylitics-ui-collage-item-image,
    img.stylitics-image {
        -webkit-filter: drop-shadow(2.5px 2.5px 8px rgba(0, 0, 0, 0.3));
        filter: drop-shadow(2.5px 2.5px 8px rgba(0, 0, 0, 0.3)) !important;
    }

    .stylitics-dimension-collage-card {
        background: $light-grey;
    }
}
