@import "../../../variables";
@import 'bootstrap/scss/mixins/breakpoints';

.card-component {
    overflow-wrap: break-word;

    &-title {
        &__img {
            width: rem(24);
            height: rem(24);
        }
    }

    &-footer {
        &__btn {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: rem(200);
            }
        }
    }
}
