@import '../../../global/variables';
@import 'bootstrap/scss/mixins/breakpoints';

@mixin hero-banner-stack-columns() {
    .hero-banner-media {
        .hero-banner & {
            margin: -16px 0 16px -32px;
            position: relative;
            top: 0;
            transform: translateX(50%);
            width: calc(100% + 32px);
        }
    }

    .hero-banner.short & {
        min-height: 0;

        .hero-banner-media {
            height: 150px;
        }
    }

    .hero-banner.medium & {
        min-height: 0;

        .hero-banner-media {
            height: 250px;
        }
    }

    .hero-banner.tall &,
    .hero-banner.screen-height & {
        min-height: 0;

        .hero-banner-media {
            height: 350px;
        }
    }

    &[class*="justify-content-md"] {
        .hero-banner.half-and-half & {
            .hero-banner-media {
                left: auto;
                max-width: calc(100% + 32px);
                right: 50%;
            }
        }
    }
}

@mixin hero-banner-half-and-half-non-stacking() {
    .hero-banner-media {
        transform: translateY(-50%);
    }

    &.justify-content-md-start {
        .hero-banner-media {
            left: auto;
            right: 0;
        }
    }

    &.justify-content-md-end {
        .hero-banner-media {
            left: 0;
            right: auto;
        }
    }
}

.hero-banner {
    display: flex;
    overflow: hidden;
    position: relative;

    .hero-banner-container {
        display: flex;
        height: 100%;
        padding: 16px;
        width: 100%;

        @include media-breakpoint-up(lg) {
            padding: 50px;
        }
    }

    .embedded-component {
        width: 100%;

        &:empty {
            display: none;
        }

        .experience-region {
            &:empty {
                display: none;
            }
        }
    }

    &.short {
        min-height: 150px;

        @include media-breakpoint-up(md) {
            min-height: 80px;
        }
    }

    &.medium {
        min-height: 250px;

        @include media-breakpoint-up(md) {
            min-height: 300px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 350px;
        }
    }

    &.tall {
        min-height: 211px;

        @include media-breakpoint-up(md) {
            min-height: 650px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 700px;
        }
    }

    &.screen-height {
        min-height: 100vh;

        .fixed-header ~ div & {
            min-height: calc(100vh - 50px);

            @include media-breakpoint-up(md) {
                min-height: calc(100vh - 70px);
            }

            @include media-breakpoint-up(lg) {
                min-height: calc(100vh - 64px);
            }
        }

        .experience-main > .experience-component:first-child & {
            min-height: calc(100vh - 91px);

            @include media-breakpoint-up(md) {
                min-height: calc(100vh - 111px);
            }

            @include media-breakpoint-up(lg) {
                min-height: calc(100vh - 105px);
            }
        }
    }
}

.hero-banner-media {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    width: 100%;

    &.hero-banner-media-tablet,
    &.hero-banner-media-desktop {
        display: none;
    }

    @include media-breakpoint-up(md) {
        &.hero-banner-media-mobile,
        &.hero-banner-media-desktop {
            display: none;
        }

        &.hero-banner-media-tablet {
            display: block;
        }
    }

    @include media-breakpoint-up(lg) {
        &.hero-banner-media-mobile,
        &.hero-banner-media-tablet {
            display: none;
        }

        &.hero-banner-media-desktop {
            display: block;
        }
    }
}

.hero-banner.half-and-half {
    @include media-breakpoint-down(sm) {
        height: auto;

        .hero-banner-container {
            flex-flow: column !important;
        }

        .hero-banner-media {
            margin: -16px 0 16px -32px;
            position: relative;
            top: 0;
            transform: translateX(50%);
            width: calc(100% + 32px);
        }

        &.short {
            min-height: 0;

            .hero-banner-media {
                height: 150px;
            }
        }

        &.medium {
            min-height: 0;

            .hero-banner-media {
                height: 250px;
            }
        }

        &.tall,
        &.screen-height {
            min-height: 0;

            .hero-banner-media {
                height: 211px;
            }
        }
    }

    @include media-breakpoint-only(md) {
        .hero-banner-container:not(.flex-md-column) {
            @include hero-banner-half-and-half-non-stacking();
        }
    }

    @include media-breakpoint-up(lg) {
        @include hero-banner-half-and-half-non-stacking();
    }
}

.flex-column {
    @include media-breakpoint-down(sm) {
        @include hero-banner-stack-columns();
    }
}

.flex-md-column {
    @include media-breakpoint-only(md) {
        @include hero-banner-stack-columns();
    }
}
