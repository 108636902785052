@import '../../../global/variables';
@import 'bootstrap/scss/mixins/breakpoints';

$breakpoint-small: 544px;

.multi-column-layout {
    .experience-region,
    .experience-commerce_assets-TextOnlyBlock {
        height: 100%;
    }

    .tob-container-main {
        height: 100%;

        &.row {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        &.mb-05 {
            margin-bottom: 0 rem;
        }
    }
}

.tob-container-main {
    &.mb-05 {
        margin-bottom: 0.125rem;
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: right;
    }

    &.text-white {
        h1,
        h2,
        h3,
        h4,
        p {
            color: $white !important;
        }
    }

    &.text-black {
        h1,
        h2,
        h3,
        h4,
        p {
            color: $black !important;
        }

        &.center {
            justify-content: center;
        }

        &.right {
            justify-content: right;
        }

        &.left {
            justify-content: left;
        }
    }

    .tob-container {
        max-width: 800px;

        @include media-breakpoint-down(md) {
            padding: 1.5rem 1rem !important;
        }

        .headline-container {
            font-family: $nb-page-heading-font !important;
            font-size: 3.25rem;
            line-height: 3.5rem;
            width: 100%;
            margin: auto;
            font-weight: 500;

            &.center {
                text-align: center;
                justify-content: center;
            }

            &.right {
                text-align: right;
                justify-content: right;
                width: 100%;
            }

            &.left {
                text-align: left;
                justify-content: left;
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: 2.25rem;
            }
        }

        .intro-container,
        .body-container {
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-family: $body-font-family !important;
            width: 100%;

            &.center {
                text-align: center;
                justify-content: center;
                justify-items: center;
            }

            &.right {
                text-align: right;
                justify-content: right;
                justify-items: right;
            }
        }

        .body-container {
            h1,
            h2,
            h3,
            h4,
            p {
                margin-bottom: 0;
                max-width: 800px !important;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 0.5rem !important;
            }
        }

        .cta-container {
            &.cta-update {
                width: 100%;
            }

            &.center {
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
            }

            &.left {
                justify-content: left;
            }

            &.right {
                justify-content: right;
            }

            .cta-box {
                .button-text-link {
                    padding: 0.625rem 0;

                    &.right {
                        text-align: right;
                    }

                    &.left {
                        text-align: left;
                    }

                    &.center {
                        text-align: center;
                    }
                }
            }
        }
    }

    .disclaimer-container {
        font-size: 0.75rem;
        line-height: 1.125rem;

        .disclaimer-box {
            max-width: 1000px;
        }

        &.center {
            text-align: center;
            justify-content: center;
        }

        &.left {
            text-align: left;
        }

        &.right {
            text-align: right;
            justify-content: right;
        }
    }
}
