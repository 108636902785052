@import "bootstrap/scss/mixins/breakpoints";
@import "../../../variables";

.experience-commerce_assets-videoComponent {
    video.frame-height {
        height: auto;

        @include media-breakpoint-up(lg) {
            height: calc(100vh - 140px);
        }
    }

    .frame-height {
        max-height: rem(360);

        @include media-breakpoint-up(lg) {
            max-height: rem(680);
        }
    }
}

.nb-pd-video {
    position: relative;
    width: 100%;

    .video-controls {
        position: absolute;
        top: 15px;
        right: 15px;
        display: flex;
        align-items: flex-end;
        border-radius: 5px;
        z-index: 10;

        @include media-breakpoint-down(md) {
            top: 15px;
            right: 10px;
        }

        .play-pause {
            background: transparent;
            padding: 5px;
            cursor: pointer;
            margin-left: 3px;

            img {
                // Preventing pointer events so that the user can click the parent instead
                pointer-events: none;
                left: 62px;
                border-radius: 5px;
                background-color: rgba(0, 0, 0, 0.7);
                height: 40px;
                padding: 5px;
                position: static;
                @include media-breakpoint-down(md) {
                    height: 24px;
                    left: 42px;
                }
            }

            .btn-pause {
                display: inline-block;
            }

            .btn-play {
                display: none;
            }

            &.paused {
                .btn-pause {
                    display: none;
                }

                .btn-play {
                    display: inline-block;
                }
            }
        }

        .volume {
            background: transparent;
            padding: 5px;
            cursor: pointer;
            padding-right: 0 !important;

            img {
                left: 10px;
                border-radius: 5px;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.7);
                height: 40px;
                padding: 5px;
                position: static;
                @include media-breakpoint-down(md) {
                    height: 24px;
                }
            }

            .btn-mute {
                display: none;
            }

            .btn-unmute {
                display: inline-block;
            }

            &.muted {
                .btn-mute {
                    display: inline-block;
                }

                .btn-unmute {
                    display: none;
                }
            }
        }
    }

    video::-webkit-media-controls-play-button,
    video::-webkit-media-controls-volume-control-container,
    video::-webkit-media-controls-fullscreen-button {
        display: none;
    }
}
