@import '../../../global/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.text-image-split-container {
    overflow: hidden;

    .headline-box,
    .email-success-text {
        &.garamond {
            font-family: $nb-page-heading-font !important;
            font-size: 3.5rem;
            line-height: 3.75rem;

            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: 2.25rem;
            }
        }

        &.proxima {
            font-family: $body-font-family !important;
            font-size: 2.625rem;
            line-height: 2.875rem;

            @include media-breakpoint-down(md) {
                font-size: 1.5rem;
                line-height: 1.75rem;
            }
        }
    }

    .intro-box,
    .body-box {
        @include media-breakpoint-down(md) {
            font-size: 0.875rem !important;
            line-height: 1.25rem !important;
            font-family: $body-font-family !important;
        }
    }

    .video-controls {
        @include media-breakpoint-up(lg) {
            &.left {
                right: 0;
                left: 24px;
            }
        }
    }

    .comp-j {
        @include media-breakpoint-down(md) {
            padding-bottom: 40px;
        }
    }
}
