.experience-commerce_assets-productCarousel {
    .header {
        @include media-breakpoint-up(lg) {
            padding: 0 40px !important;
        }

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    .horizontal-product-carousel {
        @include media-breakpoint-up(lg) {
            padding: 18px 0 !important;
        }

        &.slick-initialized {
            @include media-breakpoint-down(md) {
                margin-left: -2px;
                margin-right: -8px;
                margin-bottom: 0;
            }
        }
    }

    .carousel-ctn:has(.slick-initialized) {
        @include media-breakpoint-down(md) {
            margin-left: -8px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: -8px;
            margin-right: -8px;
        }
    }

    .carousel-ctn:not(:has(.slick-initialized)) {
        .header {
            margin-left: -8px;
        }
    }
}

.experience-commerce_layouts-Tabs {
    .tabbed-product-carousel:has(.slick-initialized) {
        @include media-breakpoint-down(md) {
            margin-left: -2px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: -8px;
            margin-right: -8px;
        }
    }

    .tabbed-carousel {
        &.slick-initialized {
            @include media-breakpoint-down(md) {
                margin-bottom: 4px;
            }
        }
    }
}
